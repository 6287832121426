import React from "react";
import {Link} from "gatsby";
import Slider from "react-slick";
import slide1 from '../images/slide1.webp';
import slide2 from '../images/slide2.webp';
import slide3 from '../images/slide3.webp';

const SectionSlider = ({ experience }) => {
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
        };

        return (
            <div>
                <Slider {...settings}>
                    <div>
                        <div className="flex sm:min-h-3/4 items-center" style={{
                            backgroundImage: `url(${slide3})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center center',
                            backgroundSize: 'cover',
                        }}>
                            <div className="container mx-auto md:flex flex-col content-around">
                                <div className="rounded py-6 px-8 lg:w-4/6"
                                     style={{backgroundColor: 'rgba(0,0,0,0.5)'}}>
                                    <h1 className="text-gray-100 font-bold leading-tight text-3xl md:text-4xl lg:text-5xl">
                                        Profesjonalne usługi medyczne z { experience } letnim doświadczeniem.
                                    </h1>
                                    <p className="text-gray-300 font-semibold mt-3 text-lg">
                                        Placówka medyczna LENSMED od lat działa w Sandomierzu, wykonując profesjonalne usługi medyczne dla swoich pacjentów. Nasz gabinet lekarski w Sandomierzu posiada szeroką gamę usług. Sprawdź, co możemy Ci zaoferować.
                                    </p>
                                    <div className="md:flex mt-6">
                                        <Link className="btn-main btn py-3 px-12 w-full block text-center"
                                              to="/kontakt/">Umów wizytę</Link>
                                        <Link
                                            className="rounded-lg border-2 border-main hover:bg-black hover:border-black hover:text-white transition-colors duration-500 text-main font-bold py-3 px-12 md:ml-4 w-full block text-center mt-2 md:mt-0"
                                            to="/lekarze">Lekarze</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="flex sm:min-h-3/4 items-center" style={{
                            backgroundImage: `url(${slide2})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center center',
                            backgroundSize: 'cover',
                        }}>
                            <div className="container mx-auto md:flex flex-col content-around">
                                <div className="rounded py-6 px-8 lg:w-4/6"
                                     style={{backgroundColor: 'rgba(0,0,0,0.5)'}}>
                                    <h1 className="text-gray-100 font-bold leading-tight text-3xl md:text-4xl lg:text-5xl">Nowa jakość w obsłudze pacjentów.</h1>
                                    <p className="text-gray-300 font-semibold mt-3 text-lg">
                                        Placówka medyczna LENSMED od lat działa w Sandomierzu, wykonując profesjonalne usługi medyczne dla swoich pacjentów. Nasz gabinet lekarski w Sandomierzu posiada szeroką gamę usług. Sprawdź, co możemy Ci zaoferować.
                                    </p>
                                    <div className="md:flex mt-6">
                                        <Link className="btn-main btn py-3 px-12 w-full block text-center"
                                              to="/kontakt/">Umów wizytę</Link>
                                        <Link
                                            className="rounded-lg border-2 border-main hover:bg-black hover:border-black hover:text-white transition-colors duration-500 text-main font-bold py-3 px-12 md:ml-4 w-full block text-center mt-2 md:mt-0"
                                            to="/o-firmie">O firmie</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="flex sm:min-h-3/4 items-center" style={{
                            backgroundImage: `url(${slide1})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center center',
                            backgroundSize: 'cover',
                        }}>
                            <div className="container mx-auto md:flex flex-col content-around">
                                <div className="rounded py-6 px-8 lg:w-4/6"
                                     style={{backgroundColor: 'rgba(0,0,0,0.5)'}}>
                                    <h1 className="text-gray-100 font-bold leading-tight text-3xl md:text-4xl lg:text-5xl">Kompleksowa diagnostyka i doradztwo medyczne.</h1>
                                    <p className="text-gray-300 font-semibold mt-3 text-lg">
                                        Placówka medyczna LENSMED od lat działa w Sandomierzu, wykonując profesjonalne usługi medyczne dla swoich pacjentów. Nasz gabinet lekarski w Sandomierzu posiada szeroką gamę usług. Sprawdź, co możemy Ci zaoferować.
                                    </p>
                                    <div className="md:flex mt-6">
                                        <Link className="btn-main btn py-3 px-12 w-full block text-center"
                                              to="/kontakt/">Umów wizytę</Link>
                                        <Link
                                            className="rounded-lg border-2 border-main hover:bg-black hover:border-black hover:text-white transition-colors duration-500 text-main font-bold py-3 px-12 md:ml-4 w-full block text-center mt-2 md:mt-0"
                                            to="/aktualnosci">Aktualności</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Slider>
            </div>
        );
    };

export default SectionSlider;