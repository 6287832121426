import React from "react"
import {graphql, Link} from "gatsby"

import SectionSlider from "../components/SectionSlider";
import slide from '../images/slide.webp';
import Layout from "../components/layout"
import SEO from "../components/seo";
import PostPreview from "../components/postPreview";
import SpecializationPreview from "../components/specializationPreview";

class Homepage extends React.Component {
    render() {
        const {data} = this.props;
        const siteTitle = data.site.siteMetadata.title;
        const posts = data.allContentfulPost.edges;
        const specialization = data.allContentfulSpecialization.edges;
        const experience = (new Date().getFullYear()) - 2011;
        const doctors = data.allContentfulDoctor.edges;

        return (
            <Layout location={this.props.location} title={siteTitle}>
                <SEO title="Strona główna"/>
                <div className="">
                    <SectionSlider experience={experience} />
                </div>

                <div className="py-24 bg-white">
                    <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
                        <div className="lg:text-center">
                            <p className="text-base text-main font-semibold tracking-wide uppercase leading-none">PROFESJONALIZM</p>
                            <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
                                Doświadczenie, któremu możesz zaufać.
                            </h3>
                            <p className="mt-4 max-w-2xl text-xl leading-7 text-gray-700 lg:mx-auto">
                                W naszej placówce pacjent stawiany jest na pierwszym miejscu i obsługiwany kompleksowo - od momentu przyjścia, przez rejestrację, diagnostykę, leczenie oraz późniejsze działania prewencyjne.
                            </p>
                        </div>

                        <div className="mt-10">
                            <ul className="md:grid md:grid-cols-2 md:col-gap-8 md:row-gap-10">
                                <li>
                                    <div className="flex">
                                        <div className="flex-shrink-0">
                                            <div
                                                className="flex items-center justify-center h-12 w-12 rounded-md bg-main text-white">
                                                <svg className="h-6 w-6" stroke="currentColor" fill="none"
                                                     viewBox="0 0 24 24">
                                                    <path strokeLinecap="round" strokeLinejoin="round"
                                                          strokeWidth="2"
                                                          d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"/>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ml-4">
                                            <h5 className="text-lg leading-6 font-medium text-gray-900 font-semibold">Doświadczenie</h5>
                                            <p className="mt-2 text-base leading-6 text-gray-700">
                                                Marka LENSMED od wielu lat pomaga pacjentom z Sandomierza i okolic, bogata oferta usług medycznych i kosmetycznych sprawia, że jesteśmy jednym z najlepszych obiektów w regionie.
                                            </p>
                                        </div>
                                    </div>
                                </li>
                                <li className="mt-10 md:mt-0">
                                    <div className="flex">
                                        <div className="flex-shrink-0">
                                            <div
                                                className="flex items-center justify-center h-12 w-12 rounded-md bg-main text-white">
                                                <svg className="h-6 w-6" stroke="currentColor" fill="none"
                                                     viewBox="0 0 24 24">
                                                    <path strokeLinecap="round" strokeLinejoin="round"
                                                          strokeWidth="2"
                                                          d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3"/>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ml-4">
                                            <h5 className="text-lg leading-6 font-medium text-gray-900 font-semibold">Profesjonalizm</h5>
                                            <p className="mt-2 text-base leading-6 text-gray-700">
                                                Każdy z naszych lekarzy specjalistów posiada wieloletnie doświadczenie w zawodzie oraz liczne certyfikaty potwierdzające jego umiejętności, których z miesiąca na miesiąc przybywa.
                                            </p>
                                        </div>
                                    </div>
                                </li>
                                <li className="mt-10 md:mt-0">
                                    <div className="flex">
                                        <div className="flex-shrink-0">
                                            <div
                                                className="flex items-center justify-center h-12 w-12 rounded-md bg-main text-white">
                                                <svg className="h-6 w-6" stroke="currentColor" fill="none"
                                                     viewBox="0 0 24 24">
                                                    <path strokeLinecap="round" strokeLinejoin="round"
                                                          strokeWidth="2" d="M13 10V3L4 14h7v7l9-11h-7z"/>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ml-4">
                                            <h5 className="text-lg leading-6 font-medium text-gray-900 font-semibold">Szybkość</h5>
                                            <p className="mt-2 text-base leading-6 text-gray-700">
                                                Staramy się przyjmować pacjentów w szybkich terminach w sprawach niecierpiących zwłoki. Tak samo przeprowadzamy diagnozę i leczenie, z zachowaniem najwyższych standardów praktyki lekarskiej.
                                            </p>
                                        </div>
                                    </div>
                                </li>
                                <li className="mt-10 md:mt-0">
                                    <div className="flex">
                                        <div className="flex-shrink-0">
                                            <div
                                                className="flex items-center justify-center h-12 w-12 rounded-md bg-main text-white">
                                                <svg className="h-6 w-6" stroke="currentColor" fill="none"
                                                     viewBox="0 0 24 24">
                                                    <path strokeLinecap="round" strokeLinejoin="round"
                                                          strokeWidth="2"
                                                          d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"/>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ml-4">
                                            <h5 className="text-lg leading-6 font-medium text-gray-900 font-semibold">Komunikacja</h5>
                                            <p className="mt-2 text-base leading-6 text-gray-700">
                                                Nasi specjaliści są do Twojej dyspozycji. Jeżeli nie jesteś czegoś pewny możesz zawsze zadzwonić do nas i umówić wizytę. Z chęcią odpowiemy na Twoje pytania.
                                            </p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <section className="lg:flex items-center bg-orange-100">
                    <div className="lg:w-1/2">
                        <img alt="Zdjęcie gabinetu lekarskiego w Sandomierzu" className="lg:rounded-r-lg" src={slide} />
                    </div>
                    <div className="lg:w-1/2 container mx-auto py-8 xl:py-0 md:w-full sm:px-8 md:px-16 xl:px-32">
                        <div className="flex w-12 h-12 items-center justify-around bg-main rounded-lg">
                            <svg className="fill-current text-white w-6 h-6" viewBox="0 0 192 192"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path d="m155.109 74.028a4 4 0 0 0 -3.48-2.028h-52.4l8.785-67.123a4.023 4.023 0 0 0 -7.373-2.614l-63.724 111.642a4 4 0 0 0 3.407 6.095h51.617l-6.962 67.224a4.024 4.024 0 0 0 7.411 2.461l62.671-111.63a4 4 0 0 0 .048-4.027z" />
                            </svg>
                        </div>

                        <h3 className="text-3xl xl:text-4xl font-extrabold leading-tight tracking-tight mt-6">Od lat kompleksowo pomagamy w leczeniu tysięcy pacjentów.</h3>
                        <p className="text-gray-900 text-xl mt-6">
                            Naszą główną dziedziną działań naszych gabinetów lekarskich w Sandomierzu jest okulistyka, ale w zakres naszych usług wchodzi także chirurgia, medycyna estetyczna, neurologia, psychiatria czy kardiologia.
                        </p>
                        <div className="flex justify-between lg:w-3/4 mt-6">
                            <div className="mr-8">
                                <h4 className="text-main leading-none text-5xl font-extrabold">{ doctors.length }</h4>
                                <p className="mt-0 text-lg text-gray-700 font-semibold">Lekarzy</p>
                            </div>
                            <div className="mr-8">
                                <h4 className="text-main leading-none text-5xl font-extrabold">{ specialization.length }</h4>
                                <p className="mt-0 text-lg text-gray-700 font-semibold">Specjalizacji</p>
                            </div>
                            <div className="mr-8">
                                <h4 className="text-main leading-none text-5xl font-extrabold">{ experience }</h4>
                                <p className="mt-0 text-lg text-gray-700 font-semibold">Lat doświadczenia</p>
                            </div>
                        </div>

                        {/*<Link to="/kontakt/" className="btn btn-main py-3 px-6 inline-block mt-8">Sprawdź nas</Link>*/}
                    </div>
                </section>

                <section className="py-8 md:py-12 lg:py-16 mx-auto container">
                    <div className="pb-4 md:pb-10">
                        <div className="sm:flex sm:items-center">
                            <div className="inline-flex w-12 h-12 items-center justify-around bg-main rounded-lg">
                                <svg className="fill-current text-white w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                    <path d="M315.793 19.932h-59.796v37.372h41.109v70.384c0 61.82-50.297 112.117-112.117 112.117S72.873 189.509 72.873 127.689V57.304h39.864V19.932h-58.55c-10.321 0-18.686 8.365-18.686 18.686v89.071c0 82.431 67.058 149.489 149.489 149.489s149.489-67.058 149.489-149.489V38.618c0-10.321-8.365-18.686-18.686-18.686z"/>
                                    <path d="M388.668 292.749v88.448c0 51.518-41.913 93.431-93.431 93.431s-93.431-41.913-93.431-93.431V259.114h-37.372v122.083c0 72.122 58.674 130.803 130.803 130.803 72.122 0 130.803-58.681 130.803-130.803v-88.448h-37.372z"/>
                                    <path d="M407.361 171.912c-38.132 0-69.145 31.013-69.145 69.139 0 38.12 31.013 69.139 69.139 69.139 38.126 0 69.139-31.019 69.145-69.139-.001-38.126-31.019-69.139-69.139-69.139zm-.006 100.906c-17.521 0-31.773-14.251-31.773-31.766s14.251-31.766 31.773-31.766c17.515 0 31.766 14.251 31.766 31.766s-14.251 31.766-31.766 31.766zM112.114 0c-10.321 0-18.686 8.365-18.686 18.686V58.55c0 10.321 8.365 18.686 18.686 18.686S130.8 68.871 130.8 58.55V18.686C130.8 8.365 122.435 0 112.114 0zM256.62 0c-10.321 0-18.686 8.365-18.686 18.686V58.55c0 10.321 8.365 18.686 18.686 18.686s18.686-8.365 18.686-18.686V18.686C275.306 8.365 266.941 0 256.62 0z"/>
                                </svg>
                            </div>
                            <h3 className="text-4xl leading-none font-bold text-gray-800 mt-2 sm:mt-0 sm:ml-4">Nasi specjaliści</h3>
                        </div>
                        <p className="text-gray-700 md:w-1/2 mt-3">Oferujemy szeroką gamę usług medycznych, a nasi lekarze specjaliści posiadają szereg dyplomów i cerytfikatów. Sprawdź, w czym możemy Ci pomóc</p>
                    </div>
                    <div className="flex flex-wrap grid grid-cols-1 md:grid-cols-2 gap-3 my-2">
                        { specialization.map(({ node }, index) => {
                            return (
                                <SpecializationPreview specialization={node} key={index} />
                            )
                        })}
                    </div>

                    <div className="text-center mt-8">
                        <Link to="/lekarze" className="btn btn-main py-3 px-8">Wszyscy lekarze</Link>

                    </div>
                </section>

                <section className="py-8 md:py-16 lg:py-24 mx-auto container">
                    <div className="pb-4 md:pb-10">
                        <div className="md:flex justify-between items-center">
                            <div className="sm:flex sm:items-center">
                                <div className="inline-flex w-12 h-12 items-center justify-around bg-main rounded-lg">
                                    <svg className="fill-current text-white w-6 h-6" viewBox="-21 -47 682.66669 682" xmlns="http://www.w3.org/2000/svg"><path d="m552.011719-1.332031h-464.023438c-48.515625 0-87.988281 39.472656-87.988281 87.988281v283.972656c0 48.421875 39.300781 87.824219 87.675781 87.988282v128.871093l185.183594-128.859375h279.152344c48.515625 0 87.988281-39.472656 87.988281-88v-283.972656c0-48.515625-39.472656-87.988281-87.988281-87.988281zm-83.308594 330.011719h-297.40625v-37.5h297.40625zm0-80h-297.40625v-37.5h297.40625zm0-80h-297.40625v-37.5h297.40625zm0 0"/></svg>
                                </div>
                                <h1 className="text-4xl leading-none font-bold text-gray-800 mt-2 sm:mt-0 sm:ml-4">Najnowsze aktualności</h1>
                            </div>
                            <div>
                                <Link to="/aktualnosci/" className="my-4 md:my-0 btn-main btn py-2 px-6 flex justify-between items-center">
                                    <span>Więcej na blogu</span>
                                    <svg className="h-4 w-4 ml-4 fill-current text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 492.004 492.004">
                                        <path d="M484.14 226.886L306.46 49.202c-5.072-5.072-11.832-7.856-19.04-7.856-7.216 0-13.972 2.788-19.044 7.856l-16.132 16.136c-5.068 5.064-7.86 11.828-7.86 19.04 0 7.208 2.792 14.2 7.86 19.264L355.9 207.526H26.58C11.732 207.526 0 219.15 0 234.002v22.812c0 14.852 11.732 27.648 26.58 27.648h330.496L252.248 388.926c-5.068 5.072-7.86 11.652-7.86 18.864 0 7.204 2.792 13.88 7.86 18.948l16.132 16.084c5.072 5.072 11.828 7.836 19.044 7.836 7.208 0 13.968-2.8 19.04-7.872l177.68-177.68c5.084-5.088 7.88-11.88 7.86-19.1.016-7.244-2.776-14.04-7.864-19.12z"/>
                                    </svg>
                                </Link>
                            </div>
                        </div>
                        {/*<p className="text-gray-700 mt-3 lg:w-1/2">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aperiam autem consequuntur dolorem hic itaque magnam nulla quisquam sunt ullam! Ex facere impedit ipsa obcaecati placeat quia, rem sed similique veniam!</p>*/}
                    </div>
                    <div className="flex flex-wrap mb-8">
                        {posts.map(({ node }, index) => {
                            return (
                                <PostPreview post={node} key={index} />
                            )
                        })}
                    </div>
                </section>
            </Layout>
        )
    }
}

export default Homepage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulDoctor {
      edges {
        node {
          name
        }
      }
    }
    allContentfulSpecialization {
      edges {
        node {
          name
          slug
          excerpt
          doctor {
            id
          }
        }
      }
    }
    allContentfulPost(sort: {fields: createdAt, order: DESC}, limit: 2) {
      edges {
        node {
          createdAt(formatString: "DD.MM.Y HH:mm")
          title
          slug
          excerpt
          heroImage {
            fluid {
              src
            }
            title
          }
          category {
            slug
            title
          }
          content {
            childContentfulRichText {
              html
            }
          }
          author {
            slug
            name
            avatar {
              title
              fluid {
                srcSet
              }
            }
          }
        }
      }
    }
  }
`
